import React, { useState, useEffect } from 'react';

const BACKEND_URL = 'https://board.havania.app';
export default function DrawingInfoModal({ drawing, onClose }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    if (drawing) {
      setImageUrl(`${BACKEND_URL}/api/drawings/${drawing._id}/image`);
      setAudioUrl(`${BACKEND_URL}/api/drawings/${drawing._id}/audio`);
    }
  }, [drawing]);

  if (!drawing) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Learning Info</h2>
        {/* <p><strong>Title:</strong> {drawing.title}</p>
        <p><strong>Created:</strong> {new Date(drawing.createdAt).toLocaleString()}</p>
        <p><strong>Last Updated:</strong> {new Date(drawing.updatedAt).toLocaleString()}</p> */}
        
        {imageUrl && (
          <img src={imageUrl} alt="Drawing" className="mt-4 max-w-full h-auto" />
        )}
        
        {audioUrl && (
          <audio controls className="mt-4 w-full">
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
        
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
        >
          Close
        </button>
        <p className="mt-4"><strong>Drawing Id:</strong> {drawing._id}</p>
      </div>
    </div>
  );
}
