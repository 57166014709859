import React from 'react';

const LoadingBar = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-4 flex flex-col items-center">
        <div className="w-48 h-2 bg-gray-200 rounded-full mb-2">
          <div className="h-full bg-blue-500 rounded-full animate-loading-bar"></div>
        </div>
        <p className="text-sm text-gray-700">Loading drawing...</p>
      </div>
    </div>
  );
};

export default LoadingBar;
