export const getScaledPoint = (canvas, event, zoom) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
  
    return {
      x: (event.clientX - rect.left) * scaleX / zoom,
      y: (event.clientY - rect.top) * scaleY / zoom
    };
  };
  
  export const drawPoint = (canvas, point, color, tool) => {
    const context = canvas.getContext('2d');
    context.fillStyle = color;
    context.beginPath();
    context.arc(point.x, point.y, tool === 'eraser' ? 10 : 2, 0, 2 * Math.PI);
    context.fill();
  };
  
  export const drawLineSegment = (canvas, start, end, color, tool) => {
    const context = canvas.getContext('2d');
    context.strokeStyle = color;
    context.lineWidth = tool === 'eraser' ? 20 : 4;
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.stroke();
  };