import React, { useState } from 'react';
import { Pencil, Droplet, Trash2, Sticker, ChevronLeft, ChevronRight, Plus, Image, Info } from 'lucide-react';
import DrawingInfoModal from './DrawingInfoModal'; // We'll create this component next

export default function Toolbar({ tool, setTool, onClearCanvas, onNewDrawing, onNavigateDrawing, canGoBack, canGoForward, onLoadImage, currentDrawing }) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showNewDrawingForm, setShowNewDrawingForm] = useState(false);

  const tools = [
    { id: 'fill', Icon: Droplet, title: 'Fill' },
    { id: 'sticker', Icon: Sticker, title: 'Sticker' },
  ];

  return (
    <>
      <div className="flex justify-between items-center bg-white shadow-md p-2 rounded-t-lg">
        <div className="flex space-x-2">
          {tools.map(({ id, Icon, title }) => (
            <button
              key={id}
              onClick={() => setTool(id)}
              className={`p-2 rounded-full transition-colors duration-200 ${
                tool === id ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
              title={title}
            >
              <Icon size={20} />
            </button>
          ))}
          <button
            onClick={onClearCanvas}
            className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
            title="Clear Canvas"
          >
            {/* <Trash2 size={20} /> */}
          </button>
        </div>
        
        {/* <button
          onClick={() => setShowNewDrawingForm(true)}
          className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200"
          title="New Drawing"
        >
          <Plus size={24} />
        </button> */}
        
        <div className="flex items-center space-x-2">
          {/* <label className="p-2 rounded-full bg-purple-500 hover:bg-purple-600 text-white transition-colors duration-200 cursor-pointer">
            <input
              type="file"
              accept="image/*"
              onChange={onLoadImage}
              className="hidden"
            />
            <Image size={24} />
          </label> */}

          <button
            onClick={() => onNavigateDrawing('prev')}
            disabled={!canGoBack}
            className={`p-2 rounded-full transition-colors duration-200 ${
              canGoBack ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-200 text-gray-400 cursor-not-allowed'
            }`}
            title="Previous Drawing"
          >
            <ChevronLeft size={24} />
          </button>
          
          <button
            onClick={() => onNavigateDrawing('next')}
            disabled={!canGoForward}
            className={`p-2 rounded-full transition-colors duration-200 ${
              canGoForward ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-200 text-gray-400 cursor-not-allowed'
            }`}
            title="Next Drawing"
          >
            <ChevronRight size={24} />
          </button>

          <button
            onClick={() => setShowInfoModal(true)}
            className="p-2 rounded-full bg-yellow-500 hover:bg-yellow-600 text-white transition-colors duration-200"
            title="Drawing Info"
          >
            <Info size={24} />
          </button>
        </div>
      </div>

      {showNewDrawingForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <form onSubmit={(e) => { onNewDrawing(e); setShowNewDrawingForm(false); }} className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Create New Drawing</h2>
            <label htmlFor="drawing" className="block text-sm font-medium text-gray-700">Drawing</label>
            <input type="file" name="drawing" accept="image/*" required className="mb-2" id="drawing"/>
            <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
            <input type="file" name="image" accept="image/*" required className="mb-2" id="image"/>
            <label htmlFor="audio" className="block text-sm font-medium text-gray-700">Audio</label>
            <input type="file" name="audio" accept="audio/*" required className="mb-2" id="audio"/>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Create</button>
            <button type="button" onClick={() => setShowNewDrawingForm(false)} className="ml-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Cancel</button>
          </form>
        </div>
      )}

      {showInfoModal && (
        <DrawingInfoModal
          drawing={currentDrawing}
          onClose={() => setShowInfoModal(false)}
        />
      )}
    </>
  );
}