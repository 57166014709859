import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback, useRef } from 'react';
import useCanvas from '../hooks/useCanvas';
import useDrawing from '../hooks/useDrawing';

const Canvas = forwardRef(({ color, tool, socket, selectedSticker, drawingId}, ref) => {
  const [isStateLoaded, setIsStateLoaded] = useState(false);

  const {
    canvasRef,
    canvasSize,
    clearCanvas,
  } = useCanvas(socket, isStateLoaded);
  
  const { 
    startDrawing, 
    draw, 
    stopDrawing, 
    handleRemoteDraw,
  } = useDrawing(canvasRef, color, tool, socket, 1, { x: 0, y: 0 }, selectedSticker, drawingId);
  
  const loadDrawing = useCallback((drawing) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    
    if (drawing.drawingData) {
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
      };
      img.src = drawing.drawingData;
    } else {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
    setIsStateLoaded(true);
  }, []);

  useImperativeHandle(ref, () => ({
    clearCanvas,
    loadDrawing,
    getCanvas: () => canvasRef.current
  }));

  useEffect(() => {
    if (socket) {
      socket.on('drawBatch', handleRemoteDraw);
      return () => {
        socket.off('drawBatch', handleRemoteDraw);
      };
    }
  }, [socket, handleRemoteDraw]);

  return (
    <div className="relative w-full h-full">
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        className="w-full h-full"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
      />
      {!isStateLoaded && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50">
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
});

export default Canvas;