import React, { useState, useEffect, useCallback, useRef } from 'react';
import { io } from 'socket.io-client';
import { CheckCircle, XCircle } from 'lucide-react';
import ColorPalette from './components/ColorPalette';
import Canvas from './components/Canvas';
import Toolbar from './components/Toolbar';
import StickerPalette from './components/StickerPalette';
import LoadingBar from './components/LoadingBar'; // Make sure this import is present

export default function App() {
  const [color, setColor] = useState('#000000');
  const [tool, setTool] = useState('pencil');
  const [isConnected, setIsConnected] = useState(false);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [drawingId, setDrawingId] = useState(null);
  const [drawings, setDrawings] = useState([]);
  const [currentDrawingIndex, setCurrentDrawingIndex] = useState(0);
  const socketRef = useRef(null);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); // Make sure this state is added

  const BACKEND_URL = 'https://board.havania.app';
  const connectSocket = useCallback(() => {
    socketRef.current = io(BACKEND_URL, {
      path: '/api/socket.io',
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      autoConnect: true,
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true);
    });
    socketRef.current.connect();
  }, []); // No dependencies needed

  const fetchDrawings = useCallback(async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/drawings`);
      const data = await response.json();
      if (data && data.drawings) {
        setDrawings(data.drawings);
        if (data.activeDrawingId) {
          const activeIndex = data.drawings.findIndex(drawing => drawing._id === data.activeDrawingId);
          if (activeIndex !== -1) {
            setCurrentDrawingIndex(activeIndex);
            setDrawingId(data.activeDrawingId);
          }
        } else if (data.drawings.length > 0) {
          setCurrentDrawingIndex(0);
          setDrawingId(data.drawings[0]._id);
        }
      }
    } catch (error) {
      console.error('Error fetching drawings:', error);
    }
  }, []);

  const saveDrawing = useCallback(async () => {
    if (canvasRef.current && drawingId) {
      const canvas = canvasRef.current.getCanvas();

      // Check if canvas is an actual canvas element
      if (canvas instanceof HTMLCanvasElement) {
        const imageData = canvas.toDataURL();
        try {
          const response = await fetch(`${BACKEND_URL}/api/${drawingId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ drawingData: imageData }),
          });
          if (!response.ok) {
            throw new Error('Failed to save drawing');
          }
          console.log('Drawing saved successfully');
        } catch (error) {
          console.error('Error saving drawing:', error);
        }
      } else if (canvas.getCanvas && typeof canvas.getCanvas === 'function') {
        // If canvas has a getCanvas method, use it
        const actualCanvas = canvas.getCanvas();
        const imageData = actualCanvas.toDataURL();
        try {
          const response = await fetch(`${BACKEND_URL}/api/${drawingId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ drawingData: imageData }),
          });

          if (!response.ok) {
            throw new Error('Failed to save drawing');
          }
          console.log('Drawing saved successfully');
        } catch (error) {
          console.error('Error saving drawing:', error);
        }
      } else {
        console.error('Unable to access canvas methods');
      }
    }
  }, [drawingId]);

  // useEffect(() => {
  //   const saveInterval = setInterval(() => {
  //     saveDrawing();
  //   }, 10000); // Save every 5 seconds

  //   return () => clearInterval(saveInterval);
  // }, [saveDrawing]);

  useEffect(() => {
    setIsLoading(true);
    setTool('fill');
    connectSocket();
    fetchDrawings();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [connectSocket]);

  // switch drawings when drawingId changes
  useEffect(() => {
    if (drawingId) {
      const fetchDrawing = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/api/${drawingId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch drawing');
          }
          const data = await response.json();
          if (data && data.drawingData && canvasRef.current && canvasRef.current.loadDrawing) {
            canvasRef.current.loadDrawing(data);
          }
        } catch (error) {
          console.error('Error fetching drawing:', error);
        } finally {
          setIsLoading(false); // End loading
        }
      };
      fetchDrawing();
    }

  }, [drawingId]);

  const handleClearCanvas = useCallback(() => {
    if (canvasRef.current && canvasRef.current.clearCanvas) {
      canvasRef.current.clearCanvas();
    }
    setTool('pencil');
  }, []);

  const handleNewDrawing = useCallback(async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const drawingFile = formData.get('drawing');
    
    try {
      formData.delete('drawing');
      const response = await fetch(`${BACKEND_URL}/api/drawings`, {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Failed to create new drawing');
      }
      const newDrawing = await response.json();
      
      handleLoadImage(drawingFile, newDrawing._id);
      
      setDrawings(prevDrawings => [...prevDrawings, newDrawing]);
    } catch (error) {
      console.error('Error creating new drawing:', error);
    }
  }, [BACKEND_URL]);

  const handleNavigateDrawing = useCallback(async (direction) => {
    // Save the current drawing first
    setIsLoading(true);
    await saveDrawing();

    setDrawings(prevDrawings => {
      const newIndex = direction === 'next'
        ? Math.min(currentDrawingIndex + 1, prevDrawings.length - 1)
        : Math.max(currentDrawingIndex - 1, 0);
      const newDrawingId = prevDrawings[newIndex]._id;
      setCurrentDrawingIndex(newIndex);
      setDrawingId(newDrawingId);
      return prevDrawings;
    });
  }, [currentDrawingIndex, saveDrawing]);

  const handleLoadImage = async (file, drawingId) => {
    if (file && drawingId) {  // Make sure we have a current drawing
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Process the image to black and white
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const processedData = processImageToBlackAndWhite(imageData);
          ctx.putImageData(processedData, 0, 0);

          // Update the current drawing with the processed image
          const newImageData = canvas.toDataURL();
          const response = await fetch(`${BACKEND_URL}/api/${drawingId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ drawingData: newImageData }),
          });

          if (response.ok) {
            console.log('Drawing updated with new image');
          } else {
            console.error('Failed to update drawing with new image');
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to process image to black and white
  const processImageToBlackAndWhite = (imageData) => {
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      const isBlack = avg < 128;

      if (!isBlack) {
        // If not black, set to white
        data[i] = data[i + 1] = data[i + 2] = 255;
      }
      // If black, we keep the original color values
    }
    return imageData;
  };

  return (
    <div className="relative h-screen w-screen overflow-hidden flex">
      {/* Status indicator */}


      {/* Color palette sidebar - now on the right */}
      <div className="w-14 flex-shrink-0">
        <ColorPalette color={color} setColor={setColor} />
      </div>

      {/* Main content area */}
      <div className="flex-grow flex flex-col">

        {/* Canvas */}
        <div className="flex-grow relative">
          <Canvas
            ref={canvasRef}
            color={color}
            tool={tool}
            socket={socketRef.current}
            selectedSticker={selectedSticker}
            drawingId={drawingId}
          />
          {isLoading && <LoadingBar />} {/* Move this line here */}
        </div>

        <div className="bg-gray-100 p-2">
          <Toolbar
            tool={tool}
            setTool={setTool}
            onClearCanvas={handleClearCanvas}
            onNewDrawing={handleNewDrawing}
            onNavigateDrawing={(direction) => handleNavigateDrawing(direction)}
            canGoBack={currentDrawingIndex > 0}
            canGoForward={currentDrawingIndex < drawings.length - 1}
            onLoadImage={handleLoadImage}
            currentDrawing={drawings[currentDrawingIndex]} // Add this line
          />
          <div className="absolute top-2 right-2 flex items-center space-x-2">
            {isConnected ? (
              <CheckCircle className="w-5 h-5 text-green-500" />
            ) : (
              <XCircle className="w-5 h-5 text-orange-500" />
            )}
            <span className="text-xs font-medium">
              {currentDrawingIndex + 1}/{drawings.length}
            </span>
          </div>
        </div>

        {/* Sticker Palette */}
        {tool === 'sticker' && (
          <div className="bg-white shadow-md p-2">
            <StickerPalette setSelectedSticker={setSelectedSticker} />
          </div>
        )}
      </div>


    </div>
  );
}
