import { useState, useCallback } from 'react';
import { getScaledPoint, drawPoint, drawLineSegment } from '../utils/drawingUtils';
import { fillArea } from '../utils/fillUtils';

export default function useDrawing(canvasRef, color, tool, socket, scale, offset, selectedSticker, drawingId) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastPoint, setLastPoint] = useState(null);
  let updates = [];

  const getCoordinates = useCallback((event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.clientX || (event.touches && event.touches[0].clientX);
    const clientY = event.clientY || (event.touches && event.touches[0].clientY);
    
    // Calculate the scaling factors
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    // Apply scaling to the coordinates
    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;

    return { x, y };
  }, [canvasRef]);

  const drawResolutionLines = useCallback((resolutions) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformation
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.restore();

    resolutions.forEach((resolution) => {
      const { width, height } = resolution;
      const scaleX = canvas.width / width;
      const scaleY = canvas.height / height;

      ctx.save();
      ctx.scale(scale, scale);
      ctx.translate(offset.x, offset.y);
      ctx.setLineDash([5, 5]);
      ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
      ctx.beginPath();
      ctx.rect(0, 0, width, height);
      ctx.stroke();
      ctx.restore();
    });
  }, [canvasRef, scale, offset]);

  const sendResolution = useCallback(({ width, height }) => {
    socket.emit('updateResolution', {
      width,
      height
    });
  }, [socket]);

  const emitDrawEvent = useCallback((eventType, data) => {
    const eventData = { eventType, ...data };
    if (eventType === 'sticker' || eventType === 'fill') {
      socket.emit('drawBatch', drawingId, [eventData]);
    } else {
      updates.push(eventData);
    }
    if (updates.length >= 1) {
      if (socket && socket.connected) {
        socket.emit('drawBatch', drawingId, updates);
      }
      updates = [];
    }
  }, [socket, drawingId]);

  const placeSticker = useCallback((point) => {
    const canvas = canvasRef.current;
    if (canvas && selectedSticker) {
      const context = canvas.getContext('2d');
      context.save();
      context.scale(scale, scale);
      context.translate(offset.x, offset.y);
      context.font = '30px Arial';
      context.textBaseline = 'middle';
      context.textAlign = 'center';
      context.fillText(selectedSticker.emoji, point.x, point.y);
      context.restore();

      emitDrawEvent('sticker', { point, sticker: selectedSticker });
    }
  }, [canvasRef, selectedSticker, emitDrawEvent, scale, offset]);

  const startDrawing = useCallback((event) => {
    const point = getCoordinates(event);

    if (tool === 'sticker') {
      placeSticker(point);
    } else if (tool === 'fill') {
      fillArea(canvasRef.current, point, color, scale, offset);
      emitDrawEvent('fill', { point, color, scale, offset });
    } else {
      setIsDrawing(true);
      setLastPoint(point);
      drawPoint(canvasRef.current, point, color, tool, scale, offset);
      emitDrawEvent('point', { point, color, tool, scale, offset });
    }
  }, [canvasRef, color, tool, getCoordinates, emitDrawEvent, placeSticker, scale, offset]);

  const draw = useCallback((event) => {
    if (!isDrawing || tool === 'fill' || tool === 'sticker') return;

    const newPoint = getCoordinates(event);
    drawLineSegment(canvasRef.current, lastPoint, newPoint, color, tool, scale, offset);
    emitDrawEvent('line', { start: lastPoint, end: newPoint, color, tool, scale, offset });
    setLastPoint(newPoint);
  }, [canvasRef, color, tool, isDrawing, lastPoint, getCoordinates, emitDrawEvent, scale, offset]);

  const stopDrawing = useCallback(() => {
    setIsDrawing(false);
    setLastPoint(null);
  }, []);

  const handleRemoteDraw = useCallback(
    (data) => {
      data.forEach((event) => {
        const { eventType, point, start, end, color, tool, sticker, scale: remoteScale, offset: remoteOffset } = event;
        if (eventType === 'point') {
          drawPoint(canvasRef.current, point, color, tool, remoteScale, remoteOffset);
        } else if (eventType === 'line') {
          drawLineSegment(canvasRef.current, start, end, color, tool, remoteScale, remoteOffset);
        } else if (eventType === 'fill') {
          fillArea(canvasRef.current, point, color, remoteScale, remoteOffset);
        } else if (eventType === 'sticker') {
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
          context.save();
          context.scale(remoteScale, remoteScale);
          context.translate(remoteOffset.x, remoteOffset.y);
          context.font = '30px Arial';
          context.textBaseline = 'middle';
          context.textAlign = 'center';
          context.fillText(sticker.emoji, point.x, point.y);
          context.restore();
        }
      });
    },
    [canvasRef]
  );

  return {
    startDrawing,
    draw,
    stopDrawing,
    handleRemoteDraw,
    drawResolutionLines,
    sendResolution
  };
}