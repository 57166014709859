import React from 'react';
import { speak } from '../utils/voice';

export default function ColorPalette({ color, setColor }) {
  const colors = [
    { hex: '#00FF00', name: 'Green' },
    { hex: '#0000FF', name: 'Blue' },
    { hex: '#FF0000', name: 'Red' },
    { hex: '#202020', name: 'Black' },
    { hex: '#FFFFFF', name: 'White' },
    { hex: '#FFFF00', name: 'Yellow' },
    { hex: '#FF00FF', name: 'Magenta' },
    { hex: '#00FFFF', name: 'Cyan' },
    { hex: '#FFA500', name: 'Orange' },
    { hex: '#800080', name: 'Purple' },
    { hex: '#008000', name: 'Dark Green' },
    { hex: '#FFC0CB', name: 'Pink' },
    { hex: '#A52A2A', name: 'Brown' },
    { hex: '#808080', name: 'Gray' },
    { hex: '#FFD700', name: 'Gold' },
    { hex: '#40E0D0', name: 'Turquoise' },
    { hex: '#FCD7B8', name: 'Beige' },
  ];

  const handleColorClick = (hexColor, colorName) => {
    setColor(hexColor);
    speak(colorName);
  };

  return (
    <div className="h-full bg-white shadow-md p-2 flex flex-col items-center space-y-2 overflow-y-auto">
      {colors.map(({ hex, name }) => (
        <button
          key={hex}
          onClick={() => handleColorClick(hex, name)}
          className={`w-10 h-10 rounded-full transition-transform duration-200 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
            color === hex ? 'ring-2 ring-offset-2 ring-blue-500 scale-110' : ''
          }`}
          style={{ 
            backgroundColor: hex, 
            border: hex === '#FFFFFF' ? '1px solid #E5E7EB' : 'none'
          }}
          title={name}
          aria-label={`Select ${name} color`}
        />
      ))}
    </div>
  );
}